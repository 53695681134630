html {
	font-family: 'Times New Roman', Times, serif;
	background-color: gainsboro;
}

.column {
	width: 33.33%;
	float: left;
}

.row:after {
	content: "";
	clear: both;
	display: table;
}

@media screen and (max-width: 600px) {
	.column {
	  width: 100%;
	}
}

.header {
	width: 100%;
	text-align: center;
}

.wblob {
	border-style: solid;
	border-color: #a8e0b1;
	background-color: #a8e0b1;
	border-radius: 20px;
	margin: 10px;
	text-align: center;
}

.weatherTable, td {
	border-style: solid;
	border-width: 2px;
	border-radius: 2px;
	border-collapse: collapse;
	padding: 4px;

}
.navigation {
	background-color: #a8e0b1;
	margin: 0px;
	border-radius: 10px;
}
.navigation a, navItem {
	display: inline-block;
	color: black;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

.navigation a {
	text-decoration: none;
	padding: 14px 16px;
}

.nav {
	padding: 14px 16px;
}